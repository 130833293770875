import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject, of, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface Person {
  id: string;
  isActive: boolean;
  age: number;
  name: string;
  gender: string;
  company: string;
  email: string;
  phone: string;
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ForumServiceService {
  Url = `${environment.apiUrl}`;

  forumDetails: BehaviorSubject<any> = new BehaviorSubject([]);
  postCommentDataObserver: BehaviorSubject<any> = new BehaviorSubject({});
  forumfilterObserver: BehaviorSubject<any> = new BehaviorSubject({});
  notificationObserver: BehaviorSubject<any> = new BehaviorSubject({});
  forumPostDataObserver: BehaviorSubject<any> = new BehaviorSubject({});
  forumPostMetadataObserver: BehaviorSubject<any> = new BehaviorSubject({});
  forumLaunchpadObserver: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(private http: HttpClient) {}

  getSubTopic(subject_id, maintopic_id) {
    return this.http.post(this.Url + 'subject/getSubTopic', {
      maintopic_id,
      subject_id,
    });
  }

  clearBehaviorSub() {
    this.forumDetails = new BehaviorSubject([]);
    this.postCommentDataObserver = new BehaviorSubject([]);
    this.forumfilterObserver = new BehaviorSubject([]);
    this.notificationObserver = new BehaviorSubject([]);
    this.forumPostDataObserver = new BehaviorSubject([]);
    this.forumPostMetadataObserver = new BehaviorSubject([]);
    this.forumLaunchpadObserver = new BehaviorSubject([]);
  }

  generateForumPost(params) {
    this.http
      .post(this.Url + 'generateForumPost', { ...params })
      .subscribe((res: any) => {
        if (res && res.status) {
          if (res.data.mainqueries_array) {
            res.data.mainqueries_array.map(
              (el) =>
                (el.attachments = el.txt_query_attachments
                  ? JSON.parse(el.txt_query_attachments)
                  : [])
            );
          }
          this.forumDetails.next(res.data);
        } else {
          this.forumDetails.next(res.status);
        }
      });
  }
  checkisQuestionExists(params) {
    return this.http.post(this.Url + 'checkisQuestionExists', { ...params });
  }
  createForumPost(params) {
    return this.http.post(this.Url + 'createForumPost', { ...params });
  }
  createPostReply(params) {
    return this.http.post(this.Url + 'createPostReply', { ...params });
  }
  getForumPosts(params) {
    this.http
      .post(this.Url + 'getForumPosts', { ...params })
      .subscribe((res: any) => {
        if (res && res.status) {
          this.forumPostDataObserver.next(res.data);
          this.forumPostMetadataObserver.next(res.metadata);
        }
      });
  }
  getForumPostComments(int_thread_id, int_thread_type_id, filter?) {
    if(int_thread_type_id){
    this.http
      .post(this.Url + 'getForumPostComments', {
        int_thread_id,
        int_thread_type_id: int_thread_type_id,
      })
      .subscribe((res: any) => {
        if (res && res.status) {
          res.data.map(
            (el) =>
              (el.attachments = el.txt_query_attachments
                ? JSON.parse(el.txt_query_attachments)
                : [])
          );
          this.postCommentDataObserver.next({
            key: int_thread_id,
            data: res.data,
          });
          if (!res.data?.length) {
            this.getForumPosts({ ...filter });
          }
        }
      });
    }
  }
  createForumPostLike(params) {
    return this.http.post(this.Url + 'createForumPostLike', { ...params });
  }
  createForumPostrecomend(params) {
    return this.http.post(this.Url + 'createForumPostrecomend', { ...params });
  }
  editForumPostreply(params) {
    return this.http.post(this.Url + 'editForumPostreply', { ...params });
  }
  ForumPostreplydel(params) {
    return this.http.post(this.Url + 'ForumPostreplydel', { ...params });
  }
  updateDocTOCloud(file) {
    const cloudName = 'duthqxswg';

    const formData: FormData = new FormData();
    formData.append('upload_preset', 'itx0z6fi');
    formData.append('folder', 'forum');
    formData.append('file', file);
    return this.http.post(
      // `${environment.apiUrl}`+'student/uploadFile',
      this.Url+'student/uploadFile',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }
  pinForumPost(params) {
    return this.http.post(this.Url + 'markPin', { ...params });
  }
  markNoresponseForumPost(params) {
    return this.http.post(this.Url + 'markNoresponse', { ...params });
  }
  forumLaunchpadForum(params = {}) {
    this.http.post(this.Url + 'forumLaunchpad', params).subscribe((res: any) => {
      if (res && res.status) {
        this.forumLaunchpadObserver.next(res.data);
      }
    });
  }
  getforumFilterCountsForum(params) {
    this.http
      .post(this.Url + 'getforumFilterCounts', { ...params })
      .subscribe((res: any) => {
        if (res && res.status) {
          this.forumfilterObserver.next(res.data[0]);
        }
      });
  }
  getVideoListdropdownFroum(subject_id, subtopic_id) {
    return this.http.post(this.Url + 'getVideoListdropdown', {
      subtopic_id,
      subject_id,
    });
  }
  getForumNotifications() {
    this.http
      .post(this.Url + 'forumNotifications', {})
      .subscribe((res: any) => {
        if (res && res.status) {
          this.notificationObserver.next(res.data);
        }
      });
  }
  marksNotificaionsread(params) {
    return this.http.post(this.Url + 'marksNotificaionsread', { ...params });
  }

  insertCreatePostStatus(payload) {
    return this.http.post(this.Url + 'createPostStatus', payload);
  }

  saveReply(payload) {
    return this.http.post(this.Url + 'saveReply', payload);
  }

  getSaveReply(payload) {
    return this.http.post(this.Url + 'getSaveReply', payload);
  }

  getUserdetails(payload) {
    return this.http.post(this.Url + 'student/details', payload);
  }

  checkFaceToFace(payload) {
    return this.http.post(this.Url + 'getlivesessioninfo', payload);
  }

  updateOrganizationSwitchOption(payload) {
    return this.http.post(this.Url + 'updateOrganizationSwitchOption', payload);
  }
}
