import { ContactModalComponent } from 'projects/cpa/src/app/pages/modal-pages/contact-modal/contact-modal.component';
import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { SubjectService } from 'src/app/_clusters/services/subject.service';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
// import { FeedbackComponent } from 'projects/cpa/src/app/pages/modal-pages/feedback/feedback.component';
import { FeedbackComponent } from 'projects/jobs/src/app/pages/feedback/feedback.component';
import { LogsModalComponent } from 'projects/cpa/src/app/pages/modal-pages/logs-modal/logs-modal.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GameplanModalComponent } from 'projects/cpa/src/app/pages/modal-pages/gameplan-modal/gameplan-modal.component';
import * as _ from 'underscore';
import { LoginService } from 'src/app/_clusters/services/login.service';
import { ForumServiceService } from 'projects/cpa/src/app/pages/forum/forum-service.service';
import { FacsPaymentModalComponent } from 'projects/cpa/src/app/pages/modal-pages/facs-payment-modal/facs-payment-modal.component';
import { GamePlanService } from 'src/app/_clusters/services/game-plan.service';
import { AdditionalContentUpdateComponent } from 'projects/cpa/src/app/pages/modal-pages/additional-content-update/additional-content-update.component';
import { ContentUpdateComponent } from 'projects/cpa/src/app/pages/modal-pages/content-update/content-update.component';
import { PopupService } from 'src/app/_clusters/services/popup.service';
import { MacService } from 'src/app/_clusters/services/mac.service';
import { Logout, UserRequested } from 'src/app/_store/actions/auth.actions';
import { LoadSearchQuestionAction } from 'src/app/_store/actions/question.action';
import { LoadStudentAction } from 'src/app/_store/actions/student.action';
import { JobsService } from 'projects/jobs/src/app/common/jobs.service';
import { JobsPopupComponent } from '../../../../projects/jobs/src/app/common/jobs-popup/jobs-popup.component';
import { CpaEvolutionComponent } from 'projects/cpa/src/app/pages/modal-pages/cpa-evolution/cpa-evolution.component';
import { NotificationsComponent } from 'projects/cpa/src/app/pages/modal-pages/notifications/notifications.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { delay } from 'rxjs/operators';
import { MasterclassService } from 'projects/cpa/src/app/services/masterclass.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-miles-header',
    templateUrl: './miles-header.component.html',
    styleUrls: ['./miles-header.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0, height: 0 })),
            state('*', style({ opacity: 1, height: '*' })),
            transition('void <=> *', animate('0.3s ease-in-out'))
            // transition('in => out', animate('400ms ease-in-out')),
            // transition('out => in', animate('400ms ease-in-out'))
        ])
    ]
})
export class MilesHeaderComponent implements OnInit {

    // options = [{id:'Auditing',subjectId_encrypt:"c53f9b7df8fbb22dc12d0e859a5c9734"},{'Regulation', 'Financial', 'Business'}];
    isSidebarOpen = false;
    showMobileSideBar: boolean = false;
    showBackDrop: boolean = false;
    options = [{
        int_subject_id: 101,
        subject_id_encrypt: 'c53f9b7df8fbb22dc12d0e859a5c9734',
        subject_name: 'Auditing',
        subShortHand: 'AUD'
    },
    {
        int_subject_id: 102,
        subject_id_encrypt: '53625d62402e139ccc1e441dea96b90c',
        subject_name: 'Business ',
        subShortHand: 'BEC'
    },
    {
        int_subject_id: 103,
        subject_id_encrypt: 'a1a8cddccb57f50303507da5087ed062',
        subject_name: 'Financial ',
        subShortHand: 'FAR'
    },
    {
        int_subject_id: 104,
        subject_id_encrypt: '5fdf99246bcbd70b7fdb6b6b600e6d35',
        subject_name: 'Regulation',
        subShortHand: 'REG'
    }]
    selectedOption: any = this.options[0].subject_name;
    dropdownBackgroundColor: string = '#64BC44';


    // isDivHidden: boolean = true;
    // buttonText: string = 'Show Div';
    // divText: string = 'This is the div content';

    // toggleDiv(): void {
    //   this.isDivHidden = !this.isDivHidden;
    //   this.buttonText = this.isDivHidden ? 'Show Div' : 'Hide Div';
    // }
    activeDiv: number = 1;
    buttonText: string = 'Show More';
    showFaceToFaceMenu: boolean = false;
    token: any;
    subjects: any;
    allSubject: any;
    mcq_percentage: any;
    simsPercentage: any;
    videoPercentage: any;
    clicked: Event;
    pdfUrl: any;
    isLoading: boolean = false;

    toggleDiv(): void {
        if (this.activeDiv === 1) {
            this.activeDiv = 2;
            this.buttonText = 'Show Less';
        } else {
            this.activeDiv = 1;
            this.buttonText = 'Show More';
        }
    }


    @ViewChild('aud') public aud: any;
    @ViewChild('bec') public bec: any;
    @ViewChild('reg') public reg: any;
    @ViewChild('far') public far: any;
    // For Mobile Menu Begin
    @ViewChild('aud_m') public aud_m: any;
    @ViewChild('bec_m') public bec_m: any;
    @ViewChild('reg_m') public reg_m: any;
    @ViewChild('far_m') public far_m: any;
    @ViewChild('launch_m') public launch_m: any;

    // @ViewChild('facsPaymentStartupModal', { static: true }) facsPaymentStartupModal: ModalDirective;
    // @ViewChild('stateboardId') ngSelectComponent: NgSelectComponent;
    // For Mobile Menu Ends
    sidebarclick = 'sidebar-expand';
    bodyblock = 'body-block';
    menuhide = 'menu-show';
    logohide = true;
    falarge = 'fa-normal';
    fnav = 'fnav-normal';
    textAlign = 'text-left';
    // search = '';
    launch = 'Launch AUD';
    report = 'View Report';
    isMobile: boolean = false;
    questionText = '';
    studentDetails!: Observable<Array<any>>;
    is_forum_admin: number = 0;
    userData: any = {};
    subjectDetails: any = {};
    is_discipline_selected: number;
    modalRef!: MDBModalRef;
    Breadcrumbs: any;
    defaultImage =
        'https://storage.googleapis.com/miles-next_website-lms-gptool/MAC/user.jpg';
    modalClass = '';
    forumLaunchpadData: any = [];
    notificationData: any = [];
    notificationObserver =
        this.forumServiceService.notificationObserver.asObservable();
    forumLaunchpadObserver =
        this.forumServiceService.forumLaunchpadObserver.asObservable();

    facs_code: any;
    showFacs: boolean = false;
    menuShowNow: boolean = false;
    is_only_bridgecourse: any;
    is_cpa101_access: any;
    is_only_integrity: any;
    is_only_aicpa: any;
    dashboardMeta = this.loginService.dashboardMeta.asObservable();
    unsubscribemeta: any;
    unsubscribeMeta: any;
    is_alumni: any = 0;
    isFof: any = localStorage.getItem('islogged_fof')
    lms_type: any = '';
    is_only_aicpa_inter = 0;
    subjectName: any;
    is_tcp_expired: number;


    pdfContent: string = '';
    showContent = false;
    hideSideBar:boolean = false;

    constructor(
        private masterclassservice : MasterclassService,
        private elementRef: ElementRef,
        private store: Store<any>,
        private formBuilder: UntypedFormBuilder,
        private subjectService: SubjectService,
        private popupService: PopupService,
        private firestore: AngularFirestore,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private modalService: MDBModalService,
        private rout: ActivatedRoute,
        private forumServiceService: ForumServiceService,
        private loginService: LoginService,
        private gamePlanService: GamePlanService,
        private macservice: MacService,
        private jobsService: JobsService,
        private http: HttpClient
    ) { }

    ngOnInit() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.initialRenderMethods()
        this.checkFaceToFace();
        /* Hide the side bar if the route is us-pathway */
        if(this.router.url && this.router.url.includes('us-pathway')) {
            this.hideSideBar = true;
        }
    }

    stickToBAR(subjectId: string) {
        if (subjectId) {
            let subjectData = { "subject_id": subjectId }
            this.loginService.updateStuDiscipline(subjectData).subscribe((res: any) => {
                if (res && res.status) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                    }).then(res => {
                        this.router.navigateByUrl('/lms/launchpad').then(() => {
                            window.location.reload();
                        });
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Please try after sometime',
                    })
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Please select one subject!',
                allowOutsideClick: false,
            }).then(res => {
                window.close();
            });
        }
    }

    localUserPrioritizeSubject() {
        localStorage.setItem("reloadFromModel", "0");
        this.loginService.getupdateUserPrioritizeSubject().subscribe((res: any) => {
            localStorage.setItem('subjectId', res.data);
            this.initialRenderMethods()
        });
    }

    checkFaceToFace() {
        let payload = {}
        this.forumServiceService.checkFaceToFace(payload).subscribe((res: any) => {
            if (res.data.length == 0) {
                this.showFaceToFaceMenu = false
            } else {
                this.showFaceToFaceMenu = true
            }
        });
    }

    initialRenderMethods() {
        this.selectedOption = localStorage.getItem('subjectId');
        this.menuShowNow = true;
        this.subjectName = this.options.find((obj) => {
            return obj.subject_id_encrypt === this.selectedOption;
        });

        this.lms_type = window.location.href;
        this.isMobile = this.deviceService.isMobile();
        this.is_alumni = localStorage.getItem('enlni');
        this.rout.params.subscribe((param) => {
            this.subjectService.Breadcrumbs.subscribe((data) => {
                if (data) {
                    this.Breadcrumbs = data.subject;
                }
            });
        });
        this.store
            .select((store: any) => store.auth.user)
            .subscribe((data: any) => {

                if (data) {
                    this.subjectDetails = data;
                    this.is_discipline_selected = this.subjectDetails.some(subject => subject.is_selected_discipline !== null) ? 1 : 0;
                    this.switchTcpPopup(this.is_tcp_expired, this.is_discipline_selected)
                }
            });

        this.unsubscribemeta = this.dashboardMeta.subscribe((res: any) => {
            if (!_.isEmpty(res)) {
                this.is_only_bridgecourse = res[0].is_only_bridgecourse;
                this.is_cpa101_access = res[0].is_cpa101_access;
            }
        });

        this.loginService.getUserByToken().subscribe((data) => {
            // this.openGameplanModal();
            if (!_.isEmpty(data)) {
                this.loginService.getDashboardData(data);
                if (data.gameplan_details[0].gameplan_filled === 0) {
                    this.openGameplanModal();
                }
            }
        });

        this.studentDetails = this.store.select(
            (store: any) => store.student?.student_details
        );
        this.studentDetails.subscribe((data: any) => {
            if (data) {
                this.userData = data;
                this.is_forum_admin = data.is_forum_admin;
                if (this.userData?.is_only_iimi === 1) {
                    if (this.router.url == "/lms/launchpad") {
                        this.router.navigate(['/lms/university-programme']);
                    }
                }
                this.is_only_aicpa = data.is_only_aicpa;
                this.is_only_integrity = data.is_only_integrity;
                this.is_only_aicpa_inter = data.is_only_aicpa_inter;
                this.firestore
                    .collection('isLoggedIn')
                    .doc(data.otb_id + '')
                    .valueChanges()
                    .subscribe((res: any) => {
                        if (res) {
                            if (
                                res.token !== localStorage.getItem(environment.authTokenKey) &&
                                localStorage.getItem(environment.authTokenKey)
                            ) {
                                Swal.fire({
                                    html: `<p>Your session was terminated due to login from another browser/device
              . If it wasn't you,​ ​please change your password on next login or contact <a href="mailto:support@mileseducation.com">
              Miles Support Team</a>.</p>`,
                                    icon: 'error',
                                    backdrop: true,
                                    allowOutsideClick: false,
                                    confirmButtonColor: '#06345b',
                                    confirmButtonText: 'Ok',
                                    allowEscapeKey: false,
                                }).then((result) => {
                                    if (result.value) {
                                        // this.modalRef.hide();
                                        document.location.href = '/';
                                        // this.router.navigate(['/']);
                                    }
                                });
                            }
                        }
                    });

                this.is_tcp_expired = data?.is_tcp_expired ?? 0;
                this.switchTcpPopup(this.is_tcp_expired, this.is_discipline_selected)
            } else {
                this.store.dispatch(new LoadStudentAction());
            }
        });
        this.forumServiceService.forumLaunchpadForum();
        this.forumLaunchpadObserver.subscribe((data) => {
            if (data) {
                this.forumLaunchpadData = _.sortBy(data, 'int_subject_id');//data;
            }
        });
        this.getForumNotifications();
        this.notificationObserver.subscribe((data) => {
            if (data) {
                this.notificationData = data;
            }
        });
        this.getstudent_facsdetails();
        this.updateDropdownBackgroundColor()
    }

    switchTcpPopup(is_tcp_expired:number, is_discipline_selected: number) {
        if (is_tcp_expired === 2 && is_discipline_selected === 0 && this.is_forum_admin === 0) {
            Swal.fire({
                title: 'Info',
                html: 'Your 72-hour TCP trial has wrapped up! Now, it’s decision time – pick your discipline subject to move forward. Remember, once you choose, there’s no turning back – it’s like gluing puzzle pieces together!',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Stick with BAR',
                cancelButtonText: 'Switch to TCP',
                allowOutsideClick: false,
                customClass: {
                    cancelButton: 'swal2-cancel'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.stickToBAR('e3cabe6023121c56d1ffa9a8b12e100a');
                } else if (result.isDismissed) {
                    this.stickToBAR('9eeb9a6ec1f23e922bc44fef3efbb772');
                }
            });
        }
    }


    updateDropdownBackgroundColor() {
        // Change the background color based on the selected option
        if (this.selectedOption === 'c53f9b7df8fbb22dc12d0e859a5c9734') {
            this.dropdownBackgroundColor = '#64BC44';
        } else if (this.selectedOption === '53625d62402e139ccc1e441dea96b90c') {
            this.dropdownBackgroundColor = '#edbf6f';
        } else if (this.selectedOption === 'a1a8cddccb57f50303507da5087ed062') {
            this.dropdownBackgroundColor = '#527a9a';
        } else if (this.selectedOption === '5fdf99246bcbd70b7fdb6b6b600e6d35') {
            this.dropdownBackgroundColor = '#d4494c';
        }

    }



    navgigateToLaunchpad() {

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to change the subject!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#06345b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let selectItem = this.selectedOption;
                this.updateDropdownBackgroundColor();
                localStorage.setItem('subjectId', selectItem);
                localStorage.setItem('reloadnow', "1");
                await delay(5000);
                let currentUrl = '/lms/launchpad';
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate([currentUrl]);
            }
            else {
                this.selectedOption = localStorage.getItem('subjectId');
                this.updateDropdownBackgroundColor();
            }
        })
    }

    menuclick() {
        this.bodyblock === 'body-block'
            ? (this.bodyblock = 'body-block-lg')
            : (this.bodyblock = 'body-block');
        this.sidebarclick === 'sidebar-expand'
            ? (this.sidebarclick = 'sidebar-normal')
            : (this.sidebarclick = 'sidebar-expand');
        this.menuhide === 'menu-show'
            ? (this.menuhide = 'menu-hide')
            : (this.menuhide = 'menu-show');
        this.falarge === 'fa-normal'
            ? (this.falarge = 'fa-large')
            : (this.falarge = 'fa-normal');
        this.fnav === 'fnav-normal'
            ? (this.fnav = 'fnav-collapse')
            : (this.fnav = 'fnav-normal');
        this.textAlign === 'text-left'
            ? (this.textAlign = 'text-center')
            : (this.textAlign = 'text-left');
        this.logohide === true ? (this.logohide = false) : (this.logohide = true);
    }
    searchQuestion() {
        //// console.log(this.questionText);
        this.store.dispatch(
            new LoadSearchQuestionAction({ question_id: this.questionText })
        );
    }

    logout() {
        this.firestore
            .collection('isLoggedIn')
            .doc(this.userData.otb_id + '')
            .delete();
        this.store.dispatch(new Logout());
        localStorage.clear();
        sessionStorage.clear();
    }

    closePopover(index: any) { }
    showFeedbackModal() {
        this.modalRef = this.modalService.show(FeedbackComponent, {
            backdrop: true,
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-lg modal-dialog-scrollable feedback_modal_fix_width',
            containerClass: 'right',
            animated: true,
        });
    }
    openLogModal() {
        this.modalRef = this.modalService.show(LogsModalComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-lg modal-dialog-scrollable',
            containerClass: 'right',
            animated: true,
        });
    }
    openGameplanModal() {
        this.modalRef = this.modalService.show(GameplanModalComponent, {
            backdrop: true,
            keyboard: false,
            focus: true,
            show: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-dialog-centered modal-lg',
            containerClass: 'modal fade overflow-y',
            animated: true,
        });
    }

    openContactModal() {
        if (
            this.is_only_bridgecourse == 1 ||
            this.is_only_aicpa == 1 ||
            this.is_only_integrity == 1
        ) {
            this.showNAPopup();
            this.showNAPOPUp();
            this.router.navigateByUrl('/lms/launchpad');
        } else {
            if (this.isMobile) {
                this.modalClass = 'modal-dialog modal-fluid pr-3 w-100 mt-5 pt-4';
            } else {
                this.modalClass = 'modal-dialog modal-fluid w-75 mt-5 pt-4 contactus-modal';
            }
            this.modalRef = this.modalService.show(ContactModalComponent, {
                backdrop: true,
                keyboard: false,
                focus: true,
                show: false,
                ignoreBackdropClick: true,
                class: this.modalClass,
                containerClass: 'modal fade overflow-y',
                animated: true,
            });
        }
    }

    goToSubjectForum(id: number) {
        const audId = 'c53f9b7df8fbb22dc12d0e859a5c9734';
        const becId = '53625d62402e139ccc1e441dea96b90c';
        const farId = 'a1a8cddccb57f50303507da5087ed062';
        const regId = '5fdf99246bcbd70b7fdb6b6b600e6d35';
        if (id == 101) {
            // this.router.navigate(['/lms/forum/subject',audId]);
            window.open('/lms/forum/subject/' + audId, '_blank');
        } else if (id == 102) {
            window.open('/lms/forum/subject/' + becId, '_blank');
            // this.router.navigate(['/lms/forum/subject',becId]);
        } else if (id == 103) {
            window.open('/lms/forum/subject/' + farId, '_blank');
            // this.router.navigate(['/lms/forum/subject',farId]);
        } else if (id == 104) {
            window.open('/lms/forum/subject/' + regId, '_blank');
            // this.router.navigate(['/lms/forum/subject',regId]);
        } else {
            window.open('/lms/forum', '_blank');
            // this.router.navigate(['/lms/forum','']);
        }
    }

    getForumNotifications() {
        this.forumServiceService.getForumNotifications();
    }
    goToForum(
        type: number,
        subjectId: any,
        questionId: any,
        mainTopicId: any,
        subTopicId: any,
        videoId: any
    ) {
        if (type === 1) {
            this.router.navigate(['/lms/forum/mcq', subjectId, questionId]);
        } else if (type === 2) {
            this.router.navigate(['/lms/forum/sims', subjectId, questionId]);
        } else if (type === 3) {
            this.router.navigate([
                '/lms/forum/video',
                subjectId,
                mainTopicId,
                subTopicId,
                videoId,
            ]);
        } else if (type === 4) {
            this.router.navigate([
                '/lms/forum/ebook',
                subjectId,
                mainTopicId,
                subTopicId,
                0,
            ]);
        }
    }
    marksNotificaionsread(int_post_id?: any) {
        this.forumServiceService
            .marksNotificaionsread({ int_status: 1, int_post_id })
            .subscribe((res: any) => {
                if (res.status) {
                    this.getForumNotifications();
                }
            });
    }

    // ************** Evaluation Modal Query Begins *****************//
    evaluationPaymentModal() {
        if (
            this.is_only_bridgecourse == 1 ||
            this.is_only_aicpa == 1 ||
            this.is_only_integrity == 1
        ) {
            this.router.navigateByUrl('/lms/launchpad');
        } else {
            this.modalRef = this.modalService.show(FacsPaymentModalComponent, {
                backdrop: true,
                keyboard: true,
                focus: true,
                show: true,
                ignoreBackdropClick: true,
                class: 'modal-lg modal-dialog-scrollable',
                containerClass: 'right',
                animated: true,
            });
        }
    }
    getstudent_facsdetails() {
        this.gamePlanService.student_facsdetails().subscribe(
            (res: any) => {
                if (res.data.length && res.status) {
                    this.facs_code = res.data[0]?.facs_code;
                    this.showFacs = true;
                } else {
                    this.showFacs = false;
                }
            },
            (err) => {
                this.showFacs = false;
            }
        );
    }
    // *************** Evaluation Modal Query Ends ******************//
    // ################# Content Update Modal Begins ################# //

    additionContentUpdate() {
        this.modalRef = this.modalService.show(AdditionalContentUpdateComponent, {
            backdrop: true,
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-lg modal-dialog-scrollable',
            containerClass: 'right',
            animated: true,
        });
    }

    cpaEvolution() {
        this.modalRef = this.modalService.show(CpaEvolutionComponent, {
            backdrop: true,
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-lg modal-dialog-scrollable',
            containerClass: 'right',
            animated: true,
        });
    }

    notifiCation() {
        this.modalRef = this.modalService.show(NotificationsComponent, {
            backdrop: true,
            keyboard: false,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-lg modal-dialog-scrollable',
            containerClass: 'right',
            animated: true,
        });
    }

    contentUpdate() {
        if (
            this.is_only_bridgecourse == 1 ||
            this.is_only_aicpa == 1 ||
            this.is_only_integrity == 1
        ) {
            this.showNAPopup();
            this.showNAPOPUp();
            this.router.navigateByUrl('/lms/launchpad');
        } else {
            this.modalRef = this.modalService.show(ContentUpdateComponent, {
                backdrop: true,
                keyboard: false,
                focus: true,
                show: true,
                ignoreBackdropClick: true,
                class: 'modal-lg modal-dialog-scrollable content-updates',
                containerClass: 'right',
                animated: true,
            });
        }
    }

    checkurl() {
        if (
            this.is_only_bridgecourse == 1 ||
            this.is_only_aicpa == 1 ||
            this.is_only_integrity == 1
        ) {
            this.popupService.naInfo('Attention', 'This feature is not applicable.');
            this.router.navigateByUrl('lms/launchpad');
        } else {
            localStorage.setItem('is_mac_loggedin', '1');
            this.router.navigate(['/lms/mac/dashboard/referral']);
            // this.macservice.checkuserexists().subscribe((resp: any) => {
            //   var cond;
            //   if (document.URL.includes('cma') == true) {
            //     cond = resp.data[0]?.cma_info.firstname;
            //   } else {
            //     cond = resp.data[0]?.cpa_info?.firstname;
            //   }
            //   if (resp.data.length == 0) {
            //     this.router.navigate(['/lms/mac']);
            //   } else if (
            //     resp.data.length > 0 &&
            //     resp.data[0]?.token_expired == true
            //   ) {
            //     this.router.navigate(['/lms/mac']);
            //   } else if (resp.data.length > 0 && cond == undefined) {
            //     this.router.navigate(['/lms/mac/registration']);
            //   } else {
            //     localStorage.setItem('is_mac_loggedin', '1');
            //     this.router.navigate(['/lms/mac/dashboard/referral']);
            //   }
            // });
        }
    }

    undermaintenance() {
        this.router.navigateByUrl('lms/launchpad');
        Swal.fire({
            icon: "info",
            title: "The M.A.C portal is currently under maintenance. We apologize for the inconvenience."
        })
    }

    showAttentionPopup() {
        if (
            this.is_only_bridgecourse == 1 ||
            this.is_only_aicpa == 1 ||
            this.is_only_integrity == 1
        ) {
            this.popupService.naInfo('Attention', 'This feature is not applicable.');
            this.router.navigateByUrl('lms/launchpad');
        }
    }

    check1o1url() {
        if (this.is_only_bridgecourse == 1) {
            this.router.navigateByUrl('/lms/launchpad');
        } else {
            this.router.navigateByUrl('/lms/certificate-program');

            // this.macservice.getjobprofiledetails().subscribe((res: any) => {
            //   if (res && res.data.length > 0) {
            //     if (res.data[0].step == 1) {
            //       this.router.navigate(['/lms/jobs/onboard/step1']);
            //     } else if (res.data[0].step == 2) {
            //       this.router.navigate(['/lms/jobs/onboard/step2']);
            //     } else if (res.data[0].step == 3) {
            //       this.router.navigate(['/lms/jobs/onboard/step3']);
            //     } else if (res.data[0].step == 4) {
            //       this.router.navigate(['/lms/jobs/onboard/step4']);
            //     } else if (res.data[0].step == 5) {
            //       this.router.navigate(['/lms/jobs/onboard/step5']);
            //     } else if (res.data[0].step == 6) {
            //       this.router.navigate(['/lms/jobs/onboard/step6']);
            //     } else if (res.data[0].step == 7) {
            //       this.router.navigate(['/lms/jobs/onboard/step7']);
            //     } else if (res.data[0].step == 8) {
            //       this.router.navigate(['/lms/jobs/onboard/step8']);
            //     } else if (res.data[0].step == 9) {
            //       this.router.navigate(['/lms/jobs/onboard/step9']);
            //     } else if (res.data[0].step == 10) {
            //       this.router.navigate(['/lms/jobs/onboard/step10']);
            //     } else if (res.data[0].step == 11) {
            //       this.router.navigate(['/lms/certificate-program']);
            //     } else {
            //       this.router.navigate(['/lms/jobs']);
            //     }
            //   } else {
            //     this.router.navigate(['/lms/jobs']);
            //   }
            // });
        }
    }

    // ################## Content Update Modal Ends ################## //
    navigateTo(url) {
        if (!this.is_only_aicpa_inter) {
            this.router.navigateByUrl(url);
        } else {
            this.router.navigateByUrl('lms/cpa-inter');
        }
    }
    showNAPopup(fromInter?) {
        if (this.is_only_bridgecourse == 1) {
            this.popupService.naInfo('Attention', 'This feature is not applicable.');
            this.router.navigateByUrl('lms/launchpad');
        } else if (this.is_only_aicpa_inter && !fromInter) {
            this.popupService.naInfo('Attention', 'This feature is not available for you.');
            this.router.navigateByUrl('lms/cpa-inter');
        }
    }
    showNAPopUp() {
        if (this.is_only_aicpa == 1 || this.is_only_aicpa_inter) {
            this.popupService.naInfo('Attention', 'This feature is not applicable.');
        }
    }
    showNAPOPUp() {
        if (
            this.is_only_aicpa != 1 ||
            this.is_only_bridgecourse != 1 ||
            this.is_only_integrity == 1
        ) {
            this.router.navigateByUrl('lms/mileage/attendance');
        } else {
            this.popupService.naInfo('Attention', 'This feature is not applicable.');
            this.router.navigateByUrl('lms/launchpad');
        }
    }

    showNAPOPUpCpaInter() {
        if (
            this.is_only_aicpa != 1 ||
            this.is_only_bridgecourse != 1 ||
            this.is_only_integrity == 1
        ) {
            this.router.navigateByUrl('lms/cpa-inter');
        } else {
            this.popupService.naInfo('Attention', 'This feature is not applicable.');
            this.router.navigateByUrl('lms/launchpad');
        }
        if (this.is_only_aicpa_inter) {
            this.router.navigateByUrl('lms/cpa-inter');
        }
    }

    newMenu() {
        if (this.is_alumni == 1) {
            this.popupService.newMenuPopup(
                'Info',
                'This feature will be coming soon.'
            );
        }
    }

    toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
    }

    ngOnDestroy() {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        try {
            this.unsubscribeMeta.unsubscribe();
        } catch (error) { }
    }
    changeVersion() {

        // console.log(this.lms_type)
        if (this.lms_type.includes('lms/')) {
            const temp = this.lms_type.replace("lms/", "lms/V2/");
            // console.log(temp);
            window.location.replace(temp);
            // this.router.navigateByUrl(temp);
        }
    }

    closeMobileBtn() {
        this.showMobileSideBar = false;
    }

    navigate(url) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([url]);
    }

    comingSoon() {
        this.popupService.comingSoonPop('', 'Coming Soon!');
    }

    modal = 'modal';
    name = 'Angular';

    openModal(inp: string) {
        console.log(inp);
        this.modal = 'modal-open';
    }
    closeModal() {
        this.modal = 'modal';
    }

    showbooksNotification() {
        Swal.fire({
            title: '',
            text: "Our books are flying off the shelves and we're currently out of stock. We're working closely with McGraw Hill to restock them quickly. Apologies for the inconvenience, but don't worry, new stock will be available for pickup and shipping soon!",
            icon: 'info',
            // showCancelButton: true,
            cancelButtonText: 'Close'
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                );
            }
        });
    }


    getToken(){
        this.masterclassservice.getToken().subscribe((res:any) => {
            if(res.data){
              this.token = res.data;
              localStorage.setItem('master_token', this.token);
            }
        })
      }

     
    //Working from frontend

    // reportDownload(event: Event): void {
    //     event.preventDefault();
        
    //     this.loginService.getUserByToken().subscribe(
    //       async (data: any) => {
    //         console.log(data, "pratheek 111");
      
          
    //         this.allSubject = [];
    //         this.mcq_percentage = []; 
    //         this.simsPercentage = [];
    //         this.videoPercentage = [];
      

    //         if (data && data.subject_details) {
    //           data.subject_details.forEach(subject => {
    //             this.allSubject.push(subject.subject_name);
    //             const mcqPercentage = subject.mcq_total_questions > 0
    //               ? (subject.mcq_subject_progress / subject.mcq_total_questions) * 100
    //               : 0;
    //             this.mcq_percentage.push(mcqPercentage);

    //             const simsPercentage = subject.mcq_total_questions > 0
    //             ? (subject.sims_subject_progress / subject.sims_total_questions) * 100
    //             : 0;
    //             this.simsPercentage.push(mcqPercentage);


    //             const videoPercentage = subject.mcq_total_questions > 0
    //             ? (subject.videos_viewed / subject.total_videos) * 100
    //             : 0;
    //             this.videoPercentage.push(mcqPercentage);
      
    //             console.log(mcqPercentage);
    //             console.log(subject.subject_name);
    //           });
    //         }

    //         const filePath = '/assets/htmls/a4.html';
    //         this.http.get(filePath, { responseType: 'text' }).subscribe({
    //         next: async (data) => {
    //             console.log('File contents:', data);
    //             let subjectDetailsHTML = '';
    //             this.allSubject.forEach((subject, index) => {
    //               subjectDetailsHTML += `
    //                 <tr style="font-size: 10px; font-weight: 400; line-height: 18px; border-bottom: 1px solid #808691;">
    //                 <td style="padding: 10px 0;"><p style="color: #3399FF;">${subject}</p></td>
    //                 <td style="padding: 10px 0;">${this.mcq_percentage[index].toFixed(2)}%</td>
    //                 <td style="padding: 10px 0;">${this.simsPercentage[index].toFixed(2)}%</td>
    //                 <td style="padding: 10px 0;">${this.videoPercentage[index].toFixed(2)}%</td>
    //                 </tr>
    //               `;
    //             });
    //            let html = data.replace('{template}', subjectDetailsHTML);
    //            const container = document.createElement('div');
    //            container.innerHTML = html;
    //            document.body.appendChild(container);
   
    //            const canvas = await html2canvas(container, { scale: 5 });
    //            const imgData = canvas.toDataURL('image/png');
         
    //            const pdf = new jsPDF({
    //              orientation: 'portrait',
    //              unit: 'pt',
    //              format: 'a4', 
    //            });
         
    //            const imgProps = pdf.getImageProperties(imgData);
    //            const pdfWidth = pdf.internal.pageSize.getWidth();
    //            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
         
    //            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //            pdf.save('Progress-Report.pdf');
         
    //            document.body.removeChild(container);
    //         },
    //         error: (err) => {
    //             console.error('Error reading file:', err);
    //         }
    //         });


      
    //         const htmlTemplate = `
    //           <div style="width: 100%; padding: 20px; font-family: Arial, sans-serif;">
    //             <h1 style="text-align: center;">Progress Report</h1>
    //             <p><strong>Name:</strong> <span id="name">${name}</span></p>
    //             <p><strong>Date:</strong> <span id="date">{{date}}</span></p>
    //             <h2 style="text-align: center;">Subject Details</h2>
    //             {{subjectDetails}}
    //           </div>
    //         `;
      
    //         // const dynamicHTML = htmlTemplate
    //         //   .replace('{{name}}', 'User') 
    //         //   .replace('{{date}}', new Date().toLocaleDateString())
    //         //   .replace('{{subjectDetails}}', subjectDetailsHTML);
      
    //         // console.log(dynamicHTML); 
      
    //       },
    //       (error) => {
    //         console.error('Error fetching data from API:', error);
    //       }
    //     );
    //   } 

    reportDownload(event: Event): void {
        event.preventDefault();
        this.isLoading = true;
        this.loginService.getPdfUrl().subscribe(
            (res: any) => {
                this.isLoading = false;
                if (res && res.data && res.data.pdfUrl && res.data.pdfUrl.secure_url) {
                    this.pdfUrl = res.data.pdfUrl.secure_url;
                    const link = document.createElement('a');
                    link.href = this.pdfUrl;
                    link.download = "Progress_Report.pdf"; 
                    link.target = '_blank';
                    link.click();
                } else {
                    console.error('PDF URL is not available');
                }
            },
            (error) => {
                this.isLoading = false;
                console.error('Error fetching PDF URL:', error);
            }
        );
    }
    
}
